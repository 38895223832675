import { Component, OnInit, AfterViewChecked, Input,  ViewChild } from '@angular/core';
import { AngularFireDatabase, AngularFireObject, AngularFireList } from 'angularfire2/database';
import { MatSnackBar } from '@angular/material';
import { Observable, Subject, pipe } from 'rxjs';

import { Sort } from '@angular/material';
import {MatTableDataSource, MatSort, MatPaginator} from '@angular/material';
import {DefaultFilterService} from '../services/default-filter.service';

@Component({
  selector: 'app-crypto-table',
  templateUrl: './crypto-table.component.html',
  styleUrls: ['./crypto-table.component.scss']
})
export class CryptoTableComponent implements OnInit  {
  displayedColumns = ['name', 'symbol', 'other_price', 'change' ];
  DefaultFilter = this.filter.DefaultQuery.defaultIdk || 'ALL';
  dataSource = new MatTableDataSource();
  @Input() tableData;
  @Input() otherPirce;

  constructor(private filter: DefaultFilterService) {}

  changeFilter(e) {
    this.DefaultFilter = e.target.value;
    this.filter.DefaultQuery.defaultIdk = e.target.value;
  }
  ngAfterViewInit() {}
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}


