import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';



@Injectable()
export class GeoService {
constructor(    
    private http: HttpClient) { }
    getIpAddress() {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            return this.http
              .get('http://freegeoip.net/json/?callback',
              )
              .map(response => response || {})
              .catch(this.handleError);
          }

      private handleError(error: HttpErrorResponse): Observable<any> {
         console.error('observable error: ', error);
         return Observable.throw(error);
      }
}