
import { Component, ViewChildren, AfterViewInit, QueryList, OnInit, Input, ViewChild, AfterViewChecked } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { Observable, Subject, pipe, BehaviorSubject } from 'rxjs';

import * as Highcharts from 'highcharts/highstock';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-crypto',
  templateUrl: './crypto.component.html',
  styleUrls: ['./crypto.component.scss']
})
export class CryptoComponent implements OnInit {
  selection = new SelectionModel(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected;
    const numRows = this.dataSource.data;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['select', 'rank', 'name', 'symbol', 'price_eur'];
  dataSource;
  searchTerm: string;
  constructor(
    public globalService: GlobalService,
    public router: Router,
    private title: Title,
    private meta: Meta,
    private http: HttpClient,
    private filter: GlobalService
  ) {
    this.globalService.searchTerm.subscribe((term) => {
      this.searchTerm = term;
    });
    this.dataSource = new MatTableDataSource();

  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  ngOnInit() {
    this.title.setTitle('Crypto');
    this.meta.updateTag({ content: 'View all crypto' }, "name='description'");
    if (this.router.url.includes('crypto')) {
      this.globalService.searchTerm.next('');
    }
    this.getCryto().subscribe(data => {
      this.dataSource = data;
    });
  }
  getCryto(query?) {
    const params = new HttpParams({
      fromObject: {
        convert: query,
        limit: query
      }
    });
    return this.http.get('https://api.coinmarketcap.com/v1/ticker/', { params: params });
  }
  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
@Component({
  selector: 'crypto-table',
  templateUrl: './crypto-table.html',
  styleUrls: ['./crypto.component.scss']
})

export class CryptoTable implements OnInit {
  rows = [
    { name: 'Austin', gender: 'Male', company: 'Swimlane' },
    { name: 'Dany', gender: 'Male', company: 'KFC' },
    { name: 'Molly', gender: 'Female', company: 'Burger King' },
  ];
  columns = [
    { prop: 'name' },
    { name: 'Gender' },
    { name: 'Company' }
  ];
  displayedColumns = ['id', 'name', 'progress', 'color'];
  dataSource: MatTableDataSource<UserData>;
  crypto;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private http: HttpClient) {
    const users: UserData[] = [];
    for (let i = 1; i <= 100; i++) { users.push(createNewUser(i)); }
    this.dataSource = new MatTableDataSource(users);
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  getCryto(query?) {
    const params = new HttpParams({
      fromObject: {
        convert: query,
        limit: query
      }
    });
    return this.http.get('https://api.coinmarketcap.com/v1/ticker/', { params: params });
  }
  ngOnInit() {
    this.getCryto().subscribe(data => {
      this.crypto = data;
    });
  }
}
function createNewUser(id: number): UserData {
  const name =
    NAMES[Math.round(Math.random() * (NAMES.length - 1))] + ' ' +
    NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) + '.';

  return {
    id: id.toString(),
    name: name,
    progress: Math.round(Math.random() * 100).toString(),
    color: COLORS[Math.round(Math.random() * (COLORS.length - 1))]
  };
}

const COLORS = ['maroon', 'red', 'orange', 'yellow', 'olive', 'green', 'purple',
  'fuchsia', 'lime', 'teal', 'aqua', 'blue', 'navy', 'black', 'gray'];
const NAMES = ['Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack',
  'Charlotte', 'Theodore', 'Isla', 'Oliver', 'Isabella', 'Jasper',
  'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'];

export interface UserData {
  id: string;
  name: string;
  progress: string;
  color: string;
}
@Component({
  selector: 'crypto-chart',
  templateUrl: './crypto-chart.html',
  styleUrls: ['./crypto.component.scss']
})
export class CryptoChartComponent implements AfterViewInit {
  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) { }
  @ViewChild('chartTarget') chartTarget;
  done = false;
  ngAfterViewInit() {
    this.route.queryParamMap.subscribe(data => {
      this.http.get(`https://min-api.cryptocompare.com/data/histoday?fsym=${data['params'].symbol}&tsym=${data['params'].curr}&limit=1000`)
        .subscribe(charting => {
          if (charting['Response'] !== 'Success') {
            //this.router.navigate(['/']);
          }
          const me = charting['Data'].reduce((acc, val) => {
            acc.push([val.time * 1000, val.close]);
            return acc;
          }, []);
          Highcharts.stockChart(this.chartTarget.nativeElement, {
            rangeSelector: {
              selected: 1
            },
            title: {
              text: `${data['params'].symbol} History in ${data['params'].curr} Currency`
            },
            series: [{
              name: `${data['params'].symbol}`,
              data: me,
            }]
          });
          this.done = true;
        });
    });
  }
}


