import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Http, Response, URLSearchParams } from '@angular/http';
import 'rxjs/add/operator/map';
import {HttpModule, Headers, RequestOptions} from '@angular/http';
import 'rxjs/Rx';
import { DomSanitizer, SafeResourceUrl, SafeUrl, SafeHtml } from '@angular/platform-browser';
import { HttpHeaders } from '@angular/common/http';
import {FormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import 'rxjs/Rx';
//import {GeoService} from './geo.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-geoip',
  templateUrl: './geoip.component.html',
  styleUrls: ['./geoip.component.scss']
})

export class GeoipComponent implements OnInit { 
  lat:any;
  lng:any;
  key = 'e03e93c114b58a4759d0771d9a4040ad';
  //apiRoot: string = "http://api.ipstack.com"; 
  //apiRoot: string = "http://httpbin.org";
  apiRoot: string = 'https://ipapi.co';
  ipData;
  findIp;
  //getIpAddress;
  constructor(public http: Http, public httpClient : HttpClient, private sanitizer: DomSanitizer, //private geoService: GeoService
  ){
    if (navigator)
    {
    navigator.geolocation.getCurrentPosition( pos => {
        this.lng = +pos.coords.longitude;
        this.lat = +pos.coords.latitude;
      });
    }
  }



  getIp(query?) {
    const params = new HttpParams({
      fromObject: {
      }
    });
    //return this.http.get(this.apiRoot + '/check?access_key=' + this.key, { params: params });
    return this.http.get('https://ipapi.co/latlong/', { params: params });
  }
  getFindIp(query?) {
    const params = new HttpParams({
      fromObject: {
      }
    });
    return this.http.get('https://ipapi.co/json/', { params: params });
  }
  getIpAddress() {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .get('http://api.ipstack.com/check?access_key=e03e93c114b58a4759d0771d9a4040ad&output=json&legacy=1',
      )
      .map(response => response || {})
      .catch(this.handleError);
  }
  private handleError(error: HttpErrorResponse): Observable<any> {
    console.error('observable error: ', error);
    return Observable.throw(error);
 }
  ngOnInit(): void {
    this.getIp().subscribe(data => {
      this.ipData = data;
    });
    this.getFindIp().subscribe(data => {
      this.findIp = data;
    });
    console.log("ip");
    //this.geoService.getIpAddress().subscribe(data => {
      this.getIpAddress().subscribe(data => {

      console.log(data);
      this.getIpAddress = data;
    });
  }
  

}
// http://api.ipstack.com/check?access_key=e03e93c114b58a4759d0771d9a4040ad&output=json&legacy=1