import { Title, Meta } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { Observable, Subject, pipe } from 'rxjs';
import { RouterModule, Routes, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import { MatSnackBar } from '@angular/material';
import { GlobalService } from 'app/services/global.service';
import { LocalCartService } from 'app/services/localcart.service';
import * as firebase from 'firebase';

@Component({
  selector: 'product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.scss']
})
export class ProductCategoriesComponent implements OnInit {
  public categories: Observable<any>;
  public nav: Observable<any>;
  public theme: Observable<any>;
  public user: Observable<firebase.User>;
  public currentAdmin: any;
  public currentUser: any;
  public linkedinUrl: string = 'https://linkedin.com/in/patryk-dawczak/';
  public githubUrl: string = 'https://github.com/PatrykDawczak';
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public db: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    public globalService: GlobalService,
    public localCart: LocalCartService,
    public snackBar: MatSnackBar,
    private afireauth: AngularFireAuth,
    private afiredb: AngularFireDatabase,
    private title: Title,
    private meta: Meta
  ) {
    this.categories = db.list('/categories', ref => ref.orderByChild('weight').limitToLast(999)).valueChanges();
    this.nav = db.list('/menus/nav').valueChanges();
    this.theme = db.object('/theme').valueChanges();
    this.currentAdmin = {};
    //this.user = afAuth.authState;
    this.user.subscribe(currentUser => {
      globalService.user.next(currentUser);

      if (currentUser) {
        this.db.object('/users/' + currentUser.uid).update({
          uid: currentUser.uid,
          email: currentUser.email,
          photoURL: currentUser.photoURL,
          status: 'active'
        });

        this.db.object('/users/' + currentUser.uid).valueChanges().subscribe((user: any) => {
          if (user.cart) {
            globalService.cart.next(user.cart);
          }
        });
      }

      if (!currentUser && this.localCart.cartHasItems()) {
        this.globalService.cart.next(this.localCart.cartGetItems());
      }
    });
  }


  ngOnInit() {
    this.title.setTitle('Products');
    this.meta.updateTag({ content: 'Browse products and product categories' }, "name='description'");
  }

}
 