import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA, ApplicationRef } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { AppComponent, AppDialog } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment.prod';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from 'app/app-routing.module';

import { CryptoComponent, CryptoTable, CryptoChartComponent } from './storefront-components/crypto/crypto.component';
import { OrdersComponent } from './storefront-components/orders/orders.component';
import { PostsComponent } from './storefront-components/posts/posts.component';
import { PagesComponent } from './storefront-components/pages/pages.component';
import { PageComponent } from './storefront-components/page/page.component';
import { PostComponent } from './storefront-components/post/post.component';
import { ProductsComponent } from './storefront-components/products/products.component';
import { ProductComponent } from './storefront-components/product/product.component';
import { CartComponent } from './storefront-components/cart/cart.component';
import { CartIconComponent } from './storefront-components/cart-icon/cart-icon.component';
import { CheckoutShippingComponent } from './storefront-components/checkout-shipping/checkout-shipping.component';
import { CheckoutBillingComponent } from './storefront-components/checkout-billing/checkout-billing.component';
import { CheckoutPaymentComponent } from './storefront-components/checkout-payment/checkout-payment.component';
import { CheckoutReviewComponent } from './storefront-components/checkout-review/checkout-review.component';
import { CheckoutConfirmationComponent } from './storefront-components/checkout-confirmation/checkout-confirmation.component';
import { ProductCategoryComponent } from './storefront-components/product-category/product-category.component';
import { ProductCategoriesComponent } from './storefront-components/product-categories/product-categories.component';
import { SearchResultsComponent } from './storefront-components/search-results/search-results.component';
import { ProjectComponent } from 'app/storefront-components/project/project.component';
import { ProjectsComponent } from 'app/storefront-components/projects/projects.component';
import { PhoneSigninComponent } from './storefront-components/phone-signin/phone-signin.component';
import { ContactComponent } from './storefront-components/contact/contact.component';
import { MessageComponent } from './storefront-components/message/message.component';
import { LoginComponent } from './auth/login/login.component';

// services
import { GlobalService } from './services/global.service';
import { WindowRefService } from "./services/window-ref.service";
import { LocalCartService } from "./services/localcart.service";
import { DefaultFilterService } from './services/default-filter.service';
// directives
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { CdkTableModule } from '@angular/cdk/table';
import { RouterModule} from '@angular/router';
import { FiltercomponentComponent } from './filtercomponent/filtercomponent.component';
import { CryptoTableComponent } from './crypto-table/crypto-table.component';
import { CrytoChartComponent } from './cryto-chart/cryto-chart.component';
import { MainComponent } from './main/main.component';
import { AuthModule } from './auth/auth.module';
import { PipesModule } from './pipes/pipes.module';
import { UbsComponent } from './ubs/ubs.component';
import { PolSourceComponent } from 'app/polsource/polsource.component';
import {DataService} from './services/data.service';
import {AddDialogComponent} from './dialogs/add/add.dialog.component';
import {EditDialogComponent} from './dialogs/edit/edit.dialog.component';
import {DeleteDialogComponent} from './dialogs/delete/delete.dialog.component';
import { GeoipComponent } from './geoip/geoip.component';
import { GeoService } from './geoip/geo.service';



import { AngularFireStorageModule } from 'angularfire2/storage';
import {
  FooterComponent,
  HeaderComponent,
  UserService,
  AlertService,
  AuthGuardService,
  AuthService,
  WindowService
} from './shared';

import { SeoService } from './seo.service';
import {NgxPaginationModule} from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from '@agm/core';
import {FormlyModule} from '@ngx-formly/core'
import {FormlyMaterialModule} from '@ngx-formly/material';
@NgModule({
  imports: [
    BrowserModule,
    MaterialModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    CdkTableModule,
    HttpModule,
    AuthModule,
    PipesModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([], { enableTracing: true, useHash: false }),
    environment.production ? ServiceWorkerModule.register('/ngsw-worker.js') : [],
    ServiceWorkerModule.register('/combined-worker.js', { enabled: environment.production }),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpModule,
    NgxPaginationModule,
    AngularFireDatabaseModule,
    FormsModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA_klSKR0ewuj8bljogGUwRYxOekitt8eI'
    }),
    FormlyModule.forRoot(),
    FormlyMaterialModule
  ],
  declarations: [
    FiltercomponentComponent,
    CryptoTableComponent,
    CrytoChartComponent,
    MainComponent,
    AppComponent,
    CartComponent,
    CartIconComponent,
    CheckoutShippingComponent,
    CheckoutBillingComponent,
    CheckoutPaymentComponent,
    CheckoutReviewComponent,
    CheckoutConfirmationComponent,
    CryptoComponent,
    CryptoTable,
    CryptoChartComponent,
    OrdersComponent,
    PagesComponent,
    PageComponent,
    PostComponent,
    PostsComponent,
    ProjectComponent,
    ProjectsComponent,
    ProductsComponent,
    ProductComponent,
    ProductCategoryComponent,
    ProductCategoriesComponent,
    SearchResultsComponent,
    StopPropagationDirective,
    PhoneSigninComponent,
    ContactComponent,
    FooterComponent,
    HeaderComponent,
    MessageComponent,
    PolSourceComponent,
    AppComponent,
    AddDialogComponent,
    EditDialogComponent,
    DeleteDialogComponent,
    UbsComponent,
    AppDialog,
    GeoipComponent
  ],
  entryComponents: [
    AddDialogComponent,
    EditDialogComponent,
    DeleteDialogComponent,
    LoginComponent,
    AppDialog
  ],
  providers: [
    DataService,
    UserService,
    AlertService,
    AuthGuardService,
    AuthService,
    WindowService,
    GlobalService, 
    WindowRefService, 
    LocalCartService, 
    DefaultFilterService, SeoService, GeoService],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA]
})
export class AppModule { }
