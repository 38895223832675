import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { BehaviorSubject , Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-filtercomponent',
  templateUrl: './filtercomponent.component.html',
  styleUrls: ['./filtercomponent.component.css']
})
export class FiltercomponentComponent implements OnInit {

  @Input() filterBy;
  @Input() filerOptions;
  filter = new BehaviorSubject<string>('');
  idk(e) {
    this.filter.next(e.target.value);
  }

  constructor() { }


  ngOnInit() {
  }
}



