import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';

@Component({
  selector: 'project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  projectContent: any;
  project: any;

  constructor(
    public db: AngularFireDatabase,
    public route: ActivatedRoute,
    private title: Title,
    private meta: Meta
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
        this.projectContent = this.db.list('/projects', ref => ref.orderByChild('url').equalTo(params.url));
        this.projectContent.valueChanges().subscribe(p => {
          if (p[0].published) {
            this.project = p[0];
            this.title.setTitle(this.project.title);
            this.meta.updateTag({ content: 'View ' + this.project.title }, "name='description'");
          } else {
            this.project = {
              title: 'project Not Found',
              body: ''
            }
          }
        });
    });
  }
}
