import { Injectable } from '@angular/core';
import { Observable, Subject, pipe ,  BehaviorSubject } from 'rxjs';
import { AngularFireDatabase } from 'angularfire2/database';
import { Issue } from '../models/issue';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class DataService {
  private readonly API_URL = 'https://api.github.com/repos/angular/angular/issues';

  dataChange: BehaviorSubject<Issue[]> = new BehaviorSubject<Issue[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;

  constructor(private httpClient: HttpClient, private db: AngularFireDatabase) { }

  get data(): Issue[] {
    return this.dataChange.value;
  }

  getDialogData() {
    return this.dialogData;
  }

  /** CRUD METHODS */
  getAllIssues(): void {
    this.httpClient.get<Issue[]>(this.API_URL).subscribe(data => {
      this.dataChange.next(data);
    },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
      });
  }

  // DEMO ONLY, you can find working methods below
  addIssue(issue: Issue): void {
    this.dialogData = issue;
  }

  updateIssue(issue: Issue): void {
    this.dialogData = issue;
  }

  deleteIssue(id: number): void {
    console.log(id);
  }
  getFeed(feed): Observable<number[]> {
    const ref = this.db.list<number>(`/v0/${feed}`);
    return ref.valueChanges();
  }

  getItem(itemId): Observable<any> {
    const ref = this.db.object(`/v0/item/${itemId}`);
    return ref.valueChanges();
  }

  getUser(userId): Observable<any> {
    const ref = this.db.object(`/v0/user/${userId}`);
    return ref.valueChanges();
  }
}

