
import {map} from 'rxjs/operators';
import { Component, OnInit, Inject, AfterViewChecked } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Observable, Subject, pipe } from 'rxjs';

import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Contact } from '../../shared';
import { AlertService, UserService } from '../../shared';
import { AngularFireDatabase, AngularFireObject, AngularFireList } from 'angularfire2/database';

import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFireAuth } from 'angularfire2/auth';
import { GlobalService } from 'app/services/global.service';
import { LocalCartService } from 'app/services/localcart.service';
import * as firebase from 'firebase/app';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  itemsRef: AngularFireList<any>;
  items: Observable<any[]>;
  item: Observable<any>;
  constructor(
    private alertService: AlertService,
    private userService: UserService,
    public db: AngularFireDatabase,
    public snackBar: MatSnackBar,
    public afAuth: AngularFireAuth,
    public router: Router,
    public globalService: GlobalService,
    public localCart: LocalCartService,
    private title: Title,
    private meta: Meta) {
    this.itemsRef = db.list('contact');
    this.item = db.object('contact').valueChanges();
    this.items = this.itemsRef.snapshotChanges().pipe(map(changes => {
      return changes.map(c => ({ key: c.payload.key, ...c.payload.val() }));
    }));
  }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {
    const company = form.value.company;
    const firstname = form.value.firstname;
    const email = form.value.email;
    const message = form.value.message;
    this.userService.contactFormSend(company, firstname, email, message);
  }

  sendIt(newFirstname: string, newEmail: string, newMessage: string) {
    this.itemsRef.push({firstname: newFirstname, email: newEmail, message: newMessage });
    let snackBarRef = this.snackBar.open('This message is send!', 'OK!', {
      duration: 3000
    });
    this.router.navigateByUrl('/')

  }


}
