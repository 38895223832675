export const environment = {
    production: true,
    firebase: {
        apiKey: "AIzaSyAX9PhImTWM0PcJCYPX3USJO0ATkEoV6bI",
        authDomain: "dawczak-pm.firebaseapp.com",
        databaseURL: "https://dawczak-pm.firebaseio.com",
        projectId: "dawczak-pm",
        storageBucket: "dawczak-pm.appspot.com",
        messagingSenderId: "935802839865"
    },
    stripe: "pk_live_oEDRbVcLUwPZPo5IYh5ro1Lq"
};
