import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Observable, Subject, pipe } from 'rxjs';
import { RouterModule, Routes, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import { GlobalService } from './services/global.service';
import { LocalCartService } from 'app/services/localcart.service';
import * as firebase from 'firebase';
//import { AngularFireStorage } from 'angularfire2/storage';
import { Title, Meta } from '@angular/platform-browser';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material';
import { MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  public apiRoot: string = 'https://dawczak.pro';
  public user: Observable<firebase.User>;
  public currentAdmin: any;
  public currentUser: any;
  public title = 'app';
  public data : Date = new Date();
  public focus;
  public focus1;
  public nav: Observable<any>;
  public theme: Observable<any>;
  public linkedinUrl: string = 'https://linkedin.com/in/patryk-dawczak/';
  public githubUrl: string = 'https://github.com/PatrykDawczak';
  dialogRef: MatDialogRef<any>;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public db: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    public globalService: GlobalService,
    public localCart: LocalCartService,
    public snackBar: MatSnackBar,
    private afireauth: AngularFireAuth,
    private afiredb: AngularFireDatabase,
    //private storage: AngularFireStorage
    private cdRef: ChangeDetectorRef,
    private meta: Meta,
    public dialog: MatDialog

  ) {
    this.globalService.searchTerm.next('');
    this.globalService.searchTerm.subscribe((term) => {
      this.searchTerm = term;
    });

    this.nav = db.list('/menus/nav').valueChanges();
    this.theme = db.object('/theme').valueChanges();
    this.currentAdmin = {};
    this.user = afAuth.authState;
    this.currentAdmin = {};
    this.user = afAuth.authState;
    this.user.subscribe(currentUser => {
      globalService.user.next(currentUser);

      if (currentUser) {
        this.db.object('/users/' + currentUser.uid).update({
          uid: currentUser.uid,
          email: currentUser.email,
          photoURL: currentUser.photoURL,
          status: 'active'
        });

        this.db.object('/users/' + currentUser.uid).valueChanges().subscribe((user: any) => {
          if (user.cart) {
            globalService.cart.next(user.cart);
          }
        });
      }

      if (!currentUser && this.localCart.cartHasItems()) {
        this.globalService.cart.next(this.localCart.cartGetItems());
      }
    });
  }
  login() {
    this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  logout() {
    this.globalService.cart.next(null);
    this.globalService.order.next(null);
    this.localCart.clearAll();
    this.afAuth.auth.signOut();
  }
  ngOnInit() {
    //this.title.setTitle('Search');
    this.meta.updateTag({ content: 'Search products and blog posts' }, "name='description'");
  }

  public ngAfterViewInit(): void {
    //this.elementRef.nativeElement.focus();
    this.cdRef.detectChanges();
  }

  performSearch(event) {
    this.globalService.searchTerm.next(event);
  }
  @ViewChild('searchit') private elementRef: ElementRef;
  searchTerm: string;


appDialog() {
  const dialogRef = this.dialog.open(AppDialog, {
    height: '350px'
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
  });
}
}

@Component({
selector: 'app-dialog',
templateUrl: 'dialog.html',
})
export class AppDialog {}