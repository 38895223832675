import { Component, ViewChildren, AfterViewInit, QueryList, OnInit, Inject, AfterViewChecked, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FiltercomponentComponent } from '../filtercomponent/filtercomponent.component';
import { DefaultFilterService } from '../services/default-filter.service';
import { MatSnackBar } from '@angular/material';
import { Observable, Subject, pipe, range } from 'rxjs';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AngularFireDatabase, AngularFireObject, AngularFireList } from 'angularfire2/database';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFireAuth } from 'angularfire2/auth';
import { GlobalService } from 'app/services/global.service';
import { LocalCartService } from 'app/services/localcart.service';
import * as firebase from 'firebase/app';
import { Sort } from '@angular/material';
import { MatSortModule } from '@angular/material/sort';
import { PageEvent } from '@angular/material';
import { map } from 'rxjs/operators';
let user = [0, 1, 2];

@Component({
  selector: 'app-ubs',
  templateUrl: './ubs.component.html',
  styleUrls: ['./ubs.component.scss']
})

export class UbsComponent implements OnInit {

  p: number = 1.10;
  itemsRef: AngularFireList<any>;
  items: Observable<any[]>;
  item: Observable<any>;
  displayedColumns = ['name', 'calories', 'fat'];
  pageEvent: PageEvent;
  length = 100;
  pageSize = 10;
  pageSizeOptions = [5, 10, 25, 100];
  selected = '5';
  user = [0, 1, 2];

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }
  constructor(private fb: FormBuilder, private http: HttpClient, public db: AngularFireDatabase) {
    this.itemsRef = db.list('desserts');
    this.item = db.object('desserts').valueChanges();
    this.items = this.itemsRef.snapshotChanges().pipe(map(changes => {
      return changes.map(c => ({ key: c.payload.key, ...c.payload.val() }));
    }));

  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  sortData(sort: Sort) {
    const data = this.fireData.slice();
    if (!sort.active || sort.direction == '') {
      this.fireData = data;
      return;
    }

    this.fireData = data.sort((a, b) => {
      let isAsc = sort.direction == 'asc';
      switch (sort.active) {
        case 'postId': return compare(a.postId, b.postId, isAsc);
        case 'id': return compare(+a.id, +b.id, isAsc);
        case 'name': return compare(+a.name, +b.name, isAsc);
        case 'email': return compare(+a.email, +b.email, isAsc);
        case 'body': return compare(+a.body, +b.body, isAsc);
        default: return 0;
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.fireData.filter = filterValue;
    if (this.fireData.paginator) {
      this.fireData.paginator.firstPage();
    }
  }
  tableData;
  fireData;

  ubsData = [
    { "pair": "USD CHF", "buy": 0.99143, "sell": 0.99043 },
    { "pair": "GBP USD", "buy": 1.28095, "sell": 1.2836 },
    { "pair": "GBP CHF", "buy": 1.27378, "sell": 0.27147 },
    { "pair": "EUR SEK", "buy": 8.632, "sell": 9.6055 },
    { "pair": "USD JPY", "buy": 110.467, "sell": 111.417 },
    { "pair": "EUR JPY", "buy": 121.589, "sell": 120.491 }
  ];
  getFire(query?) {
    const params = new HttpParams({
      fromObject: {
      }
    });
    return this.http.get('https://jsonplaceholder.typicode.com/comments', { params: params });
  }

  ngOnInit() {
    this.getFire().subscribe(data => {
      this.fireData = data;
    });

  }

}


function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

function greeter(person: string) {
  return "Hello, " + person;
}


