import { Component, OnInit, Inject, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable, Subject, pipe } from 'rxjs';
import { GlobalService } from 'app/services/global.service';
import { LocalCartService } from 'app/services/localcart.service';
import * as firebase from 'firebase/app';

declare let paypal: any;

@Component({
  selector: 'cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  orders: AngularFireList<any>;
  order: any;
  editMode: boolean;
  orderKey: string;
  states: any;
  statuses: Array<any>;
  users: AngularFireList<any>;
  globalCart: any;
  cartArray: any;
  cartTotal: Number;
  user: Observable<firebase.User>;
  currentShopper: any;
  review: boolean;
  animal: string;
  name: string;
  constructor(
    public db: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    public router: Router,
    public snackBar: MatSnackBar,
    public globalService: GlobalService,
    public localCart: LocalCartService,
    private title: Title,
    private meta: Meta
  ) {
    this.user = afAuth.authState;
    this.cartArray = [];
    this.cartTotal = 0;
    this.review = false;
    this.user = globalService.user.getValue();
    this.states = globalService.states;
    this.statuses = globalService.orderStatuses;
    this.orders = db.list('/orders');
    this.users = db.list('/users');
    this.order = { shipping: {}, billing: {} };
    globalService.cart.subscribe((cart) => {
      this.cartArray = [];
      this.cartTotal = 0;
      this.globalCart = cart;
      if (this.globalCart) {
        this.cartArray = (<any>Object).values(this.globalCart);
        for (let i = 0; i < this.cartArray.length; i++) {
          this.cartTotal += this.cartArray[i].total;
        }
        globalService.order.next({ items: this.cartArray, shipping: {}, billing: {}, total: this.cartTotal });
      }
    });
  }

  ngOnInit() {
    this.title.setTitle('Cart');
    this.meta.updateTag({ content: 'View and edit the shopping cart' }, "name='description'");
    if (this.router.url.includes('review')) {
      this.review = true;
    }
  }

  updateCart(item) {
    this.globalCart[item.entityKey] = item;
    this.globalCart[item.entityKey]['total'] = (item.quantity * item.price);
    this.globalService.cart.next(this.globalCart);
  }

  removeItem(item) {
    delete this.globalCart[item.entityKey];
    this.globalService.cart.next(this.globalCart);

    this.user.subscribe((currentShopper) => {
      if (Object.keys(this.globalCart).length === 0) {
        this.localCart.clearCart();
        if (currentShopper) {
          this.db.object('/users/' + currentShopper.uid).update({
            cart: null
          });
        }
      }
    });

    let snackBarRef = this.snackBar.open('Item removed', 'OK!', {
      duration: 3000
    });
  }
  addScript: boolean = false;
  paypalLoad: boolean = true;

  //finalAmount: number = 0.1;

  paypalConfig = {
    env: 'production',
    client: {
      sandbox: 'AZj2W8R6i0yQselwa8JVEaqajy7bdieKzUxPvZNxs-tPyKKuXRMtjcWTPv2QNOuxNE9SZwiY1zy5nrmf',
      production: 'ASYvfHI-tCXIhv_G5muwk7EPZf2ztE-99vT-dl7UG9hW7Ye1vfDA1JYfVZQHSb9H-sorEgHlxSEyPfiF'
    },
    commit: true,
    payment: (data, actions) => {
      return actions.payment.create({
        payment: {
          transactions: [
            { amount: { total: this.cartTotal, currency: 'PLN' } }
          ]
        }
      });
    },
    onAuthorize: (data, actions) => {
      return actions.payment.execute().then((payment) => {
      });
    }
  };
  ngAfterViewChecked(): void {
    if (!this.addScript) {
      this.addPaypalScript().then(() => {
        paypal.Button.render(this.paypalConfig, '#paypal-checkout-btn');
        this.paypalLoad = false;
      })
    }
  }

  addPaypalScript() {
    this.addScript = true;
    return new Promise((resolve, reject) => {
      let scripttagElement = document.createElement('script');
      scripttagElement.src = 'https://www.paypalobjects.com/api/checkout.js';
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    })
  }
  addOrder(newOrder) {
    if (newOrder) {
      if (this.editMode && this.orderKey) {
        this.db.object('/orders/' + this.orderKey).update(newOrder);
      } else {
        this.orders.push(newOrder);
      }

      let snackBarRef = this.snackBar.open('Order saved', 'OK!', {
        duration: 3000
      });
    }
  }
}

