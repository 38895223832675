import { Component, ViewChildren, AfterViewInit, QueryList, OnInit, Input, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFireDatabase } from 'angularfire2/database';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  projects: Observable<any[]>;
  searchTerm: string;
  constructor(
    public db: AngularFireDatabase,
    public globalService: GlobalService,
    public router: Router,
    private title: Title,
    private meta: Meta
  ) {
    this.projects = db.list('/projects', ref => ref.orderByChild('title').limitToLast(20)).valueChanges();
    //this.projects = db.list('/', ref => ref.orderByKey().limitToLast(20)).valueChanges();

    this.globalService.searchTerm.subscribe((term) => {
      this.searchTerm = term;
    });
  }

  ngOnInit() {
    this.title.setTitle('Dawczak.pro - Front-end Developer');
    this.meta.updateTag({ content: 'Patryk Dawczak Front-End Angular Developer with passion for new technologies. Angular has no secrets from him and if this framework was A dude they would be speeding together on downhill bikes.. This is what Patryk does in his offline, free time' }, "name='description'");

    if (this.router.url.includes('projects')) {
      this.globalService.searchTerm.next('');
    }
  }

  getProjectImage(project:any) {
    if (project.thumbnail) {
      return project.thumbnail;
    } else {
      return '../../assets/placeholder.jpg';
    }
  }
}
