export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyAX9PhImTWM0PcJCYPX3USJO0ATkEoV6bI",
        authDomain: "dawczak-pm.firebaseapp.com",
        databaseURL: "https://dawczak-pm.firebaseio.com",
        projectId: "dawczak-pm",
        storageBucket: "dawczak-pm.appspot.com",
        messagingSenderId: "935802839865"
    },
    stripe: "pk_live_oEDRbVcLUwPZPo5IYh5ro1Lq",
    sid: "AC8751925b6f6eaea42deb45ae75a12feb",
    token: "524b7b2d8a9cdb6d22fd76c5ac7389c7"
};